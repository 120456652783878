import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service.js'

export const useLandlordStore = defineStore({
  id: 'landlords',
  state: () => ({
    landlords: [],
  }),
  actions: {
    async fetchLandlords(name) {
      const params = name === 'null' || !name ? {} : { name }
      return await axiosService
        .get('/landlords', { params: params })
        .then((response) => (this.landlords = response.data))
        .catch((err) => {
          throw err
        })
    },
  },
})
