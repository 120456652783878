<script setup>
import { ref } from 'vue'
import { useManagementCompanies } from '~/stores/management-companies.js'
import BasicAutocompleteInput from '~/components/custom-formkit/BasicAutocompleteInput.vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Type to search your management company ...',
  },
  class: {
    type: String,
    default: '',
  },
})

const styleClass = props.class
const store = useManagementCompanies()

const inputValue = ref(props.context.value)

const fetchSuggestions = async (queryString) => {
  await store.fetchManagementCompanies(queryString)
  return store.response.map((company) => company.name)
}
</script>

<template>
  <BasicAutocompleteInput :context="context" :fetch-function="fetchSuggestions" :placeholder="placeholder" :class="styleClass" />
</template>
