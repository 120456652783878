import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service.js'

export const useManagementCompanies = defineStore({
  id: 'managementCompanies',
  state: () => ({
    response: [],
  }),
  actions: {
    async fetchManagementCompanies(name) {
      const params = name === 'null' || !name ? {} : { name }
      return await axiosService
        .get(`/companies`, { params: params })
        .then((response) => (this.response = response.data))
        .catch((err) => {
          throw err
        })
    },
  },
})
