import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service'
import { useRouteStore } from '~/stores/route.js'

export const useEmployeeCaseStore = defineStore({
  id: 'employee-case',
  state: () => ({
    employeeCase: null,
  }),
  getters: {},
  actions: {
    async fetchEmployee() {
      return await axiosService
        .get(`/employee-case/${useRouteStore().token}`, {
          params: {
            urlFormType: `${useRoute().params.type}`,
          },
        })
        .then((response) => (this.employeeCase = response.data))
        .catch((err) => {
          throw err
        })
    },
  },
})
