import { createInput } from '@formkit/vue'
import Autocomplete from '~/formkit-plugins/element-plus/Autocomplete'
import Checkbox from '~/formkit-plugins/element-plus/Checkbox'
import CheckboxGroup from '~/formkit-plugins/element-plus/CheckboxGroup'
import DatePicker from '~/formkit-plugins/element-plus/DatePicker'
import Input from '~/formkit-plugins/element-plus/Input'
import InputMoney from '~/formkit-plugins/element-plus/InputMoney'
import InputNumber from '~/formkit-plugins/element-plus/InputNumber'
import InputPercentage from '~/formkit-plugins/element-plus/InputPercentage'
import RadioGroup from '~/formkit-plugins/element-plus/RadioGroup'
import RadioGroupButton from '~/formkit-plugins/element-plus/RadioGroupButton'
import Select from '~/formkit-plugins/element-plus/Select'
import Switch from '~/formkit-plugins/element-plus/Switch'

export const createElementPlugin = () => (node) => {
  switch (node.props.type) {
    case 'el-input':
      return node.define(createInput(Input))
    case 'el-switch':
      return node.define(createInput(Switch))
    case 'el-date-picker':
      return node.define(createInput(DatePicker))
    case 'el-input-number':
      return node.define(createInput(InputNumber))
    case 'el-checkbox':
      return node.define(createInput(Checkbox))
    case 'el-autocomplete':
      return node.define(createInput(Autocomplete))
    case 'el-select':
      return node.define(createInput(Select))
    case 'el-radio-group':
      return node.define(createInput(RadioGroup))
    case 'el-radio-group-button':
      return node.define(createInput(RadioGroupButton))
    case 'el-checkbox-group':
      return node.define(createInput(CheckboxGroup))
    case 'el-input-percentage':
      return node.define(createInput(InputPercentage))
    case 'el-input-money':
      return node.define(createInput(InputMoney))
    default:
      return node
  }
}
