import axios from 'axios'

export function createBaseService(getHeadersFunction, $baseURL = 'https://pro.avrillo.co.uk/api') {
  const service = axios.create({
    baseURL: import.meta.env?.VITE_API_BASE || $baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  service.interceptors.request.use(
    (config) => {
      config.headers = { ...config.headers, ...getHeadersFunction(config.data) }
      return config
    },
    (error) => Promise.reject(error),
  )

  service.interceptors.response.use(
    (response) => (response.data?.meta ? response.data.data : response.data),
    (error) => Promise.reject(error),
  )
  return service
}
