<script setup>
import { computed, watch } from 'vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const store = useConveyancingCaseStore()

const clients = computed(() => {
  return store.clients
})

const clientData = ref({})

onMounted(() => {
  let initialValue = {}
  const initialShare = String(100 / clients.value.length)
  clients.value.forEach((client) => {
    initialValue[parseInt(client.user_no)] = initialShare
  })
  clientData.value = initialValue
})

const totalShareAmount = computed(() => {
  return clients.value.reduce((acc, client) => {
    return acc + Number(clientData.value[parseInt(client.user_no)] || '0')
  }, 0)
})

const totalNotEqual100 = computed(() => {
  return totalShareAmount.value !== 100
})

const hiddenNotEqualValue = computed({
  get: () => (totalNotEqual100.value ? 'yes' : 'no'),
  set: () => {},
})
</script>

<template>
  <div class="mb-4 text-sm font-medium text-black">Please insert the percentage % of distribution against your name</div>
  <div class="grid grid-cols-2 gap-4">
    <div v-for="(client, index) in clients" :key="index" class="border-[1px] border-solid p-4" :class="{ 'border-red-500': totalNotEqual100 }">
      <div class="mb-4 text-center font-medium text-black" :class="totalNotEqual100 ? 'text-red-500' : 'text-primary'">{{ client.name }}</div>
      <el-form-item label="Percentage">
        <form-kit
          v-model="clientData[parseInt(client.user_no)]"
          type="el-input"
          input-type="number"
          :name="`potential_issues.distribute_proceeds.shares.${parseInt(client.user_no)}`"
        >
          <template #append>%</template>
        </form-kit>
      </el-form-item>
    </div>
    <form-kit v-model="hiddenNotEqualValue" type="hidden" name="potential_issues.distribute_proceeds.not_equal_100" />
  </div>
  <div v-if="totalNotEqual100" class="text-md mt-6 font-medium text-red-500">The total distribution of proceeds should equal 100%</div>
</template>
