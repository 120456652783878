import { ElDatePicker } from 'element-plus'
import { computed, defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const value = computed({
      get: () => props.context._value,
      set: (val) => props.context.node.input(val),
    })

    return () =>
      h(
        ElDatePicker,
        Object.assign({}, props.context, props.context.attrs, {
          modelValue: value.value,
          valueFormat: props.context.attrs?.format ?? 'DD/MM/YYYY',
          format: props.context.attrs?.format ?? 'DD/MM/YYYY',
          type: props.context.attrs?.inputType ?? 'date',
          'onUpdate:modelValue': (val) => (value.value = val),
          onBlur: () => props.context.handlers.blur(),
        }),
      )
  },
})
