<script setup>
import { ref } from 'vue'
import BasicAutocompleteInput from '~/components/custom-formkit/BasicAutocompleteInput.vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Type to search ...',
  },
  class: {
    type: String,
    default: '',
  },
})

const styleClass = props.class

const inputValue = ref(props.context.value)

const fetchSuggestions = (queryString) => {
  const options = props.context.attrs.options || []
  const filteredResults = options.sort().filter((option) => option.toLowerCase().includes(queryString.toLowerCase()))
  return filteredResults.length > 0 ? filteredResults : options
}
</script>

<template>
  <BasicAutocompleteInput :context="context" :fetch-function="fetchSuggestions" :placeholder="placeholder" :class="styleClass" />
</template>
