<script setup>
import { ref, computed, onMounted } from 'vue'
import moment from 'moment'
import { Moon } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { isArray } from 'lodash'
import { useRouteStore } from '~/stores/route.js'
import { useSaleReadyPackStore } from '~/stores/sale-ready-packs.js'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const isLoading = ref(false)

const value = computed({
  get: () => props.context._value,
  set: (val) => {
    if (props.context.node && typeof props.context.node.input === 'function') {
      props.context.node.input(val)
    } else {
      console.error('Error: Node is not a function, it was revoked or not found.')
      ElMessage.error('We apologize for the inconvenience. An error occurred, please try again or refresh the page.')
    }
  },
})

const store = useSaleReadyPackStore()
const formStore = useFormStore()

const errors = computed(() => formStore.errors)

const filteredErrors = computed(() => {
  if (!isArray(errors.value)) return []
  return errors.value.map((error) => error.meta?.i18nArgs?.[0]?.node.name).filter((name) => !name.includes('signature'))
})

const hasFormValidationErrors = computed(() => filteredErrors.value?.length > 0)

function handleSignature() {
  if (useRouteStore().isViewOnly) {
    ElMessage.error('The form is for viewing only.')
    return
  }

  if (hasFormValidationErrors.value) {
    ElMessage.error('Please fill in all required fields.')
    return
  }

  isLoading.value = true
  setTimeout(() => {
    if (!props.context || !props.context.node) {
      ElMessage.error('We apologize for the inconvenience. An error occurred, please try again or refresh the page.')
      console.error('Error: Node is not a function, it was revoked or not found.')
      isLoading.value = false
      return
    }
    value.value = 'true'
    isLoading.value = false
  }, 3000)
}

const saleReadyPack = computed(() => store.saleReadyPack)
const form = computed(() => formStore.form)

onMounted(() => {
  if (store.saleReadyPack?.report?.proprietors.length === 0) {
    store.fetchSaleReadyPack()
  }

  if (formStore.form.length === 0) {
    formStore.fetchForm()
  }
})

const clientDetails = computed(() => {
  const proprietorMapping = {
    signature_proprietor1: saleReadyPack.value?.report?.proprietors[0]?.name,
    signature_proprietor2: saleReadyPack.value?.report?.proprietors[1]?.name,
    signature_proprietor3: saleReadyPack.value?.report?.proprietors[2]?.name,
    signature_proprietor4: saleReadyPack.value?.report?.proprietors[3]?.name,
  }

  if (!Object.prototype.hasOwnProperty.call(proprietorMapping, props.context.node.name)) {
    return ''
  }

  return proprietorMapping[props.context.node.name]
})

const clientFont = computed(() => {
  const proprietorMapping = {
    signature_proprietor1: 'font-signature-1',
    signature_proprietor2: 'font-signature-2',
    signature_proprietor3: 'font-signature-3',
    signature_proprietor4: 'font-signature-4',
  }
  return proprietorMapping[props.context.node.name] || 'default-font'
})
</script>

<template>
  <el-card v-if="clientDetails" class="box-card" body-class="flex h-full flex-col gap-3">
    <p class="text-sm font-semibold">{{ clientDetails }}</p>
    <div class="relative flex flex-1 flex-col items-center justify-center gap-1 rounded border border-dashed p-3 py-12 tracking-wide">
      <p :class="clientFont" class="ftext-center text-xl">{{ clientDetails }}</p>
      <p class="text-xs font-medium text-gray-400">{{ moment(form?.updated_at ?? Date.now()).format('DD-MM-YYYY HH:mm:ss') }}</p>

      <template v-if="!value">
        <div class="absolute inset-0 flex items-center justify-center bg-white/20 backdrop-blur-sm">
          <el-button :loading="isLoading" :disabled="isLoading" :icon="Moon" class="hover:px-6" type="primary" round @click="handleSignature">
            Check to Sign
          </el-button>
        </div>

        <el-button type="danger" size="small" class="absolute top-full -mt-3 !text-xs" plain round>Signature is required</el-button>
      </template>
    </div>
  </el-card>
</template>

<style scoped></style>
