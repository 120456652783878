<script setup>
useHead({
  titleTemplate: (titleChunk) => (titleChunk ? `${titleChunk} - AVRillo` : 'AVRillo'),
})
</script>

<template>
  <Suspense>
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>
  </Suspense>
</template>
