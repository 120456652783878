import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service'

export const useCompanyStore = defineStore({
  id: 'companies',
  state: () => ({
    companies: [],
  }),
  actions: {
    async fetchCompanies() {
      return await axiosService
        .get('/companies')
        .then((response) => (this.companies = response.data))
        .catch((err) => {
          throw err
        })
    },
  },
})
