<script setup>
import { computed } from 'vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const value = computed({
  get: () => props.context._value,
  set: (value) => props.context.node.input(value),
})

const store = useCompanyStore()

const companies = computed(() => store.companies)

onMounted(() => {
  if (store.companies.length === 0) {
    store.fetchCompanies()
  }
})
</script>

<template>
  <div>
    <el-select v-model="value">
      <el-option v-for="(company, index) in companies" :key="index" :label="company.name" :value="company.corr_index"></el-option>
    </el-select>
  </div>
</template>

<style scoped></style>
