import { default as _91token_93vGYuDp1VflMeta } from "/opt/buildhome/repo/pages/[type]/[token].vue?macro=true";
import { default as already_submittedoecHW7iMo6Meta } from "/opt/buildhome/repo/pages/errors/already_submitted.vue?macro=true";
import { default as unauthorizedaiPoIV2aI8Meta } from "/opt/buildhome/repo/pages/errors/unauthorized.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as maintenancefe5TKEsKxKMeta } from "/opt/buildhome/repo/pages/maintenance.vue?macro=true";
export default [
  {
    name: "type-token",
    path: "/:type()/:token()",
    meta: _91token_93vGYuDp1VflMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[type]/[token].vue")
  },
  {
    name: "errors-already_submitted",
    path: "/errors/already_submitted",
    component: () => import("/opt/buildhome/repo/pages/errors/already_submitted.vue")
  },
  {
    name: "errors-unauthorized",
    path: "/errors/unauthorized",
    component: () => import("/opt/buildhome/repo/pages/errors/unauthorized.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/opt/buildhome/repo/pages/maintenance.vue")
  }
]