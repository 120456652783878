import { normalizeOptions } from '@formkit/inputs'
import { ElCheckbox, ElCheckboxGroup } from 'element-plus'
import { computed, defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const options = normalizeOptions(props.context.attrs?.options ?? []).map((option) =>
      h(ElCheckbox, { label: option.__original ?? option.value, class: props.context.attrs?.class }, () => h('span', { innerHTML: option.label })),
    )
    const classes = computed(() => props.context.attrs?.class + (props.context.error ? ' error' : ''))
    const value = computed({
      get: () => props.context.value,
      set: (val) => {
        if (props.context.attrs?.soloChoice && Array.isArray(val) && val.length > 0 && val.includes(props.context.attrs?.soloChoice)) {
          if (props.context.value?.includes(props.context.attrs?.soloChoice)) {
            val = val.filter((v) => v !== props.context.attrs?.soloChoice)
          } else {
            val = [props.context.attrs?.soloChoice]
          }
        }
        props.context.node.input(val)
      },
    })

    return () =>
      h(
        ElCheckboxGroup,
        Object.assign(props.context, props.context.attrs, {
          modelValue: value.value,
          'onUpdate:modelValue': (val) => {
            value.value = val
          },
          class: classes.value,
        }),
        () => options,
      )
  },
})
