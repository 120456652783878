import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service'
import { useRouteStore } from '~/stores/route.js'

export const useConveyancingCaseStore = defineStore({
  id: 'conveyancing-cases',
  state: () => ({
    conveyancingCase: null,
  }),
  getters: {
    clients: (state) =>
      [state.conveyancingCase?.client, state.conveyancingCase?.client2, state.conveyancingCase?.client3, state.conveyancingCase?.client4].filter(Boolean),
    donors: (state) => [state.conveyancingCase?.donor_main, state.conveyancingCase?.donor_secondary].filter(Boolean),
  },
  actions: {
    async fetchConveyancingCase() {
      return await axiosService
        .get(`/conveyancing-cases/${useRouteStore().token}`, {
          params: {
            urlFormType: `${useRoute().params.type}`,
          },
        })
        .then((response) => (this.conveyancingCase = response.data))
        .catch((err) => {
          throw err
        })
    },
  },
})
