<script setup>
import { ref } from 'vue'
import BasicAutocompleteInput from '~/components/custom-formkit/BasicAutocompleteInput.vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Type to search your lender ...',
  },
  class: {
    type: String,
    default: '',
  },
  lenderType: {
    type: String,
    default: 'sale', //sale|purchase
  },
})

const styleClass = props.class
const store = useLenderStore()

const inputValue = ref(props.context.value)

const fetchSuggestions = async (queryString) => {
  await store.fetchSaleLenders(queryString)
  const lenders = props.lenderType === 'sale' ? store.saleLenders : store.purchaseLenders
  return lenders.map((item) => item.name)
}
</script>

<template>
  <BasicAutocompleteInput :context="context" :fetch-function="fetchSuggestions" :placeholder="placeholder" :class="styleClass" />
</template>
