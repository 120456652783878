import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service.js'

export const useLenderStore = defineStore({
  id: 'lenders',
  state: () => ({
    purchaseLenders: [],
    saleLenders: [],
  }),
  actions: {
    async fetchPurchaseLenders(name) {
      const params = name === 'null' || !name ? {} : { name }
      return await axiosService
        .get('/purchase-lenders', { params: params })
        .then((response) => (this.purchaseLenders = response.data))
        .catch((err) => {
          throw err
        })
    },
    async fetchSaleLenders(name) {
      const params = name === 'null' || !name ? {} : { name }
      return await axiosService
        .get('/sale-lenders', { params: params })
        .then((response) => (this.saleLenders = response.data))
        .catch((err) => {
          throw err
        })
    },
  },
})
