import { defineStore } from 'pinia'
import axiosService from '~/services/axios.proclaim.service.js'

export const useAddressStore = defineStore({
  id: 'searchAddresses',
  state: () => ({
    addresses: [],
    address: {},
    response: [],
  }),
  actions: {
    async fetchAddresses(search) {
      const response = await axiosService.get(`https://proclaim.avrillo.co.uk/api/address-finder?address=${encodeURIComponent(search)}`)
      this.addresses = response.data.map((item) => item.address)
      this.response = response.data
      return this.addresses
    },
    async getAddressesUdprn(udprn) {
      const response = await axiosService.get(`https://proclaim.avrillo.co.uk/api/address-by-udprn?udprn=${udprn}`)
      this.address = response.data
      return this.address
    },
  },
})
