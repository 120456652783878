import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service'
import { useRouteStore } from '~/stores/route.js'

export const useQuoteCaseStore = defineStore({
  id: 'quote-cases',
  state: () => ({
    quoteCase: null,
  }),
  getters: {
    clients: (state) => [state.quoteCase?.client, state.quoteCase?.client2, state.quoteCase?.client3, state.quoteCase?.client4].filter(Boolean),
    donors: (state) => [state.quoteCase?.donor_main, state.quoteCase?.donor_secondary].filter(Boolean),
  },
  actions: {
    async fetchQuoteCase() {
      return await axiosService
        .get(`/quote-cases/${useRouteStore().token}`)
        .then((response) => (this.quoteCase = response.data))
        .catch((err) => {
          throw err
        })
    },
  },
})
