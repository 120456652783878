import { createInput } from '@formkit/vue'
import AddressAutocomplete from '~/components/custom-formkit/AddressAutocomplete.vue'
import AddressFormAutocomplete from '~/components/custom-formkit/AddressFormAutocomplete.vue'
import ClientSaleDistribution from '~/components/custom-formkit/ClientSaleDistribution.vue'
import ClientsSelect from '~/components/custom-formkit/ClientsSelect.vue'
import CompaniesInput from '~/components/custom-formkit/CompaniesInput.vue'
import FileUpload from '~/components/custom-formkit/FileUpload.vue'
import LandlordAutocomplete from '~/components/custom-formkit/LandlordAutocomplete.vue'
import LandlordsInput from '~/components/custom-formkit/LandlordsInput.vue'
import LenderAutocomplete from '~/components/custom-formkit/LenderAutocomplete.vue'
import ManagementCompanyAutocomplete from '~/components/custom-formkit/ManagementCompanyAutocomplete.vue'
import SaleLendersInput from '~/components/custom-formkit/SaleLendersInput.vue'
import SelectAutocomplete from '~/components/custom-formkit/SelectAutocomplete.vue'
import SignatureInput from '~/components/custom-formkit/SignatureInput.vue'
import SignatureSaleReadyPackInput from '~/components/custom-formkit/SignatureSaleReadyPackInput.vue'

export const createCustomComponentsPlugin = () => (node) => {
  switch (node.props.type) {
    case 'sale-lenders-input':
      return node.define(createInput(SaleLendersInput))
    case 'clients-select':
      return node.define(createInput(ClientsSelect))
    case 'companies-select':
      return node.define(createInput(CompaniesInput))
    case 'landlords-select':
      return node.define(createInput(LandlordsInput))
    case 'address-autocomplete':
      return node.define(createInput(AddressAutocomplete))
    case 'address-form-autocomplete':
      return node.define(createInput(AddressFormAutocomplete))
    case 'lender-autocomplete':
      return node.define(createInput(LenderAutocomplete))
    case 'select-autocomplete':
      return node.define(createInput(SelectAutocomplete))
    case 'landlord-autocomplete':
      return node.define(createInput(LandlordAutocomplete))
    case 'management-company-autocomplete':
      return node.define(createInput(ManagementCompanyAutocomplete))
    case 'file-upload':
      return node.define(createInput(FileUpload))
    case 'client-sale-distribution':
      return node.define(createInput(ClientSaleDistribution))
    case 'signature-input':
      return node.define(createInput(SignatureInput))
    case 'signature-sale-ready-pack-input':
      return node.define(createInput(SignatureSaleReadyPackInput))
    default:
      return node
  }
}
