import { normalizeOptions } from '@formkit/inputs'
import { ElOption, ElSelect } from 'element-plus'
import { computed, defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const options = normalizeOptions(props.context.attrs?.options ?? []).map((option) =>
      h(ElOption, { label: option.label, value: option.__original ?? option.value }),
    )

    const value = computed({
      get: () => props.context.value,
      set: (val) => props.context.node.input(val),
    })

    return () =>
      h(
        ElSelect,
        Object.assign(props.context, props.context.attrs, {
          modelValue: value.value,
          'onUpdate:modelValue': (val) => (value.value = val),
        }),
        () => options,
      )
  },
})
