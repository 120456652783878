import { normalizeOptions } from '@formkit/inputs'
import { ElRadio, ElRadioGroup } from 'element-plus'
import { computed, defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const options = normalizeOptions(props.context.attrs?.options ?? []).map((option) =>
      h(ElRadio, { label: option.__original ?? option.value, class: props.context.attrs?.radioClasses, border: 'border' }, () =>
        h('span', { innerHTML: option.label }),
      ),
    )

    const classes = computed(() => props.context.attrs?.class + (props.context.error ? ' error' : ''))
    const defaultValue = computed(() => props.context?.attrs?.default || undefined)

    const value = computed({
      get: () => props.context.value,
      set: (val) => props.context.node.input(val),
    })

    onMounted(() => {
      if (value.value === undefined && defaultValue.value !== undefined) {
        props.context.node.input(defaultValue)
      }
    })
    return () =>
      h(
        ElRadioGroup,
        Object.assign(props.context, props.context.attrs, {
          modelValue: value.value,
          'onUpdate:modelValue': (val) => (value.value = val),
          // required: props.context.node.props.validation.includes('required'),
          class: classes.value,
        }),
        () => options,
      )
  },
})
