import { normalizeOptions } from '@formkit/inputs'
import { ElAutocomplete } from 'element-plus'
import { computed, defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const options = normalizeOptions(props.context.attrs?.options ?? [])

    const value = computed({
      get: () => props.context.value,
      set: (val) => props.context.node.input(val),
    })

    return () =>
      h(
        ElAutocomplete,
        Object.assign({}, props.context, props.context.attrs, {
          modelValue: value.value,
          'onUpdate:modelValue': (val) => (value.value = val),
          fetchSuggestions: (queryString, cb) => {
            const results = queryString ? options.filter((item) => item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0) : options

            cb(results)
          },
        }),
        { default: ({ item }) => h('div', {}, item.label) },
      )
  },
})
