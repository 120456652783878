import { createMultiStepPlugin } from '@formkit/addons'
import '@formkit/addons/css/multistep'
import { en } from '@formkit/i18n'
import { createProPlugin, inputs as proInputs } from '@formkit/pro'
import { defineFormKitConfig } from '@formkit/vue'
import { createCustomComponentsPlugin } from '~/formkit-plugins/custom-components'
import { createElementPlugin } from '~/formkit-plugins/element-plus'

export default defineFormKitConfig({
  locales: { en },
  locale: 'en',
  plugins: [createElementPlugin(), createMultiStepPlugin(), createProPlugin('fk-706036a4630', proInputs), createCustomComponentsPlugin()],
})
