import CryptoJS from 'crypto-js'
import { useRouteStore } from '~/stores/route.js'

export function getRequestHeaders(data) {
  const routeStore = useRouteStore()

  const reference =
    routeStore.decodedToken?.con_case_ref ??
    routeStore.decodedToken?.qc_case_ref ??
    routeStore.decodedToken?.uuid ??
    routeStore.decodedToken?.employeeRef ??
    routeStore.decodedToken?.employee_ref

  const content = !data || data instanceof FormData ? '' : JSON.stringify(data)

  return {
    Reference: reference,
    Signature: CryptoJS.HmacSHA256(`${content}${reference}`, import.meta.env?.VITE_APP_SIGNING_SECRET).toString(CryptoJS.enc.Hex),
  }
}

export function getRequestHeadersProclaim(data) {
  const content = !data || data instanceof FormData ? '' : JSON.stringify(data)

  return {
    Signature: CryptoJS.HmacSHA256(`${content}`, import.meta.env?.VITE_APP_SIGNING_SECRET).toString(CryptoJS.enc.Hex),
    Authentication: btoa('username:password'),
  }
}
