import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service'
import { useRouteStore } from '~/stores/route.js'

export const useSaleReadyPackStore = defineStore({
  id: 'sale-ready-packs',
  state: () => ({
    saleReadyPack: null,
  }),
  actions: {
    async fetchSaleReadyPack() {
      return await axiosService
        .get(`/sale-ready-packs/${useRouteStore().token}`)
        .then((response) => (this.saleReadyPack = response.data))
        .catch(async (err) => {
          if (err.response.status === 500) {
            await navigateTo('/maintenance')
          }
          throw err
        })
    },
  },
})
