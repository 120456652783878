<script setup>
import { computed, onMounted, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const files = ref([])

const isLoading = ref(false)

const value = computed({
  get: () => props.context._value,
  set: (value) => props.context.node.input(value),
})

watch(
  files,
  (newFiles) => {
    value.value = newFiles
      .filter((file) => file.response)
      .map((file) => {
        return {
          id: file.response.id,
          full_url: file.response.full_url,
          name: file.name,
        }
      })
  },
  { deep: true },
)

const store = useFormStore()

async function removeFile(uploadedFile) {
  const fileId = uploadedFile.response?.id

  if (fileId) {
    await store
      .deleteFile(fileId)
      .then(() => ElMessage.success('The file has been successfully deleted'))
      .catch((err) => {
        ElMessage.error('There was an issue deleting that file')
        throw err
      })
  }

  return true
}

async function previewFile(uploadedFile) {
  const url = uploadedFile?.response?.full_url

  window.open(url, '_blank')
}

function fetchFilesFromStore() {
  const foundFiles = store.form.form_files?.filter((file) => file.name === props.context.attrs.fileName)
  foundFiles?.forEach((file) => {
    files.value.push({
      name: file.file_name,
      status: 'success',
      response: file,
    })
  })
}

function uploadFile(option) {
  isLoading.value = true
  const MAX_FILE_SIZE = 20 * 1024 * 1024

  if (option.file.size > MAX_FILE_SIZE) {
    const error = new Error('File too large. Please upload a smaller file.')
    error.code = 'FILE_TOO_LARGE'
    error.status = 413

    isLoading.value = false
    return Promise.reject(error)
  }

  return store
    .uploadFile(option.file, props.context.attrs.fileName)
    .then((response) => {
      option.file.full_url = response?.full_url
      option.file.id = response?.id
      return response
    })
    .catch((err) => {
      throw err
    })
    .finally(() => {
      isLoading.value = false
    })
}

onMounted(() => {
  fetchFilesFromStore()
})
</script>

<template>
  <div>
    <div v-if="context.attrs.title" :class="['mb-4 text-sm font-medium text-black', { 'required-label': props.context.state?.required }]">
      {{ context.attrs.title }}
    </div>
    <el-upload
      v-model:file-list="files"
      drag
      multiple
      :http-request="uploadFile"
      :before-remove="removeFile"
      :on-preview="previewFile"
      :on-error="
        (e) => {
          ElMessage.error(
            e
              ? e.status === 413 || e.response?.status === 413
                ? 'File too large. Please upload a smaller file.'
                : e.response?.data?.message || e.response?.message || 'There was an issue uploading that file'
              : 'Unable to complete the request. Please check your network connection.',
          )
        }
      "
      :on-success="() => ElMessage.success('The file has been successfully uploaded')"
      :disabled="store?.form?.status === 'submitted' ? true : false"
    >
      <el-icon class="el-icon--upload">
        <UploadFilled />
      </el-icon>
      <div class="el-upload__text">
        Drop file here or
        <em>click to upload</em>
        <div v-loading="isLoading" class="mt-6" />
      </div>
      <template #tip>
        <div class="el-upload__tip">pdf/jpg/png/bmp files with a size less than 20mb</div>
      </template>
    </el-upload>
  </div>
</template>

<style scoped></style>
