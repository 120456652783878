<script setup>
import { computed } from 'vue'
import { useFormStatusStore } from '~/stores/form-status.js'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const isEditable = computed(() => useFormStatusStore().isEditable)

const value = computed({
  get: () => props.context._value,
  set: (value) => props.context.node.input(value),
})

const store = useConveyancingCaseStore()
</script>

<template>
  <div>
    <el-form-item :label="context.attrs.title" class="el-form-item--large">
      <el-select v-model="value" :disabled="!isEditable">
        <el-option v-for="(client, clientIndex) in store.clients" :key="clientIndex" :label="client.name" :value="client.name"></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<style scoped></style>
