import { ElInput } from 'element-plus'
import { computed, defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const value = computed({
      get: () => props.context._value,
      set: (value) => {
        if (props.context.attrs?.min) {
          const min = parseFloat(props.context.attrs?.min)
          if (value < min) value = min
        }

        if (props.context.attrs?.max) {
          const max = parseFloat(props.context.attrs?.max)
          if (value > max) value = max
        }

        props.context.handlers.DOMInput({ target: { value } })
      },
    })

    return () =>
      h(
        ElInput,
        Object.assign(props.context, props.context.attrs, {
          modelValue: value.value,
          type: 'number',
          'onUpdate:modelValue': (val) => (value.value = val),
          onBlur: () => props.context.handlers.blur(),
        }),
        {
          prepend: h('span', '£'),
        },
      )
  },
})
