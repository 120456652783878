import { defineStore } from 'pinia'

export const useRouteStore = defineStore({
  id: 'route',
  state: () => ({
    isViewOnly: false,
    token: null,
    decodedToken: null,
  }),
  actions: {
    setToken(token, decodedToken) {
      this.token = token
      this.decodedToken = decodedToken
      this.isViewOnly = !!decodedToken.isViewOnly
    },
  },
})
