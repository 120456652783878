import { ElSwitch } from 'element-plus'
import { computed, defineComponent, h } from 'vue'

export default defineComponent({
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const value = computed({
      get: () => props.context.value,
      set: (val) => props.context.node.input(val),
    })

    return () =>
      h(
        ElSwitch,
        Object.assign(props.context, props.context.attrs, {
          modelValue: value.value,
          'onUpdate:modelValue': (val) => (value.value = val),
        }),
      )
  },
})
