<script setup>
import { computed } from 'vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const value = computed({
  get: () => props.context._value,
  set: (value) => props.context.node.input(value),
})

const required = computed(() => props.context.state?.required)
const store = useLenderStore()
const disabled = computed(() => !useFormStatusStore().isEditable)

const saleLenders = computed(() => store.saleLenders)

onMounted(() => {
  if (store.saleLenders.length === 0) {
    store.fetchSaleLenders()
  }
})
</script>

<template>
  <div>
    <el-form-item :label="context.attrs.title" class="el-form-item--large" :required="required">
      <div v-if="context.attrs.subTitle" class="-mt-5 text-black">
        {{ context.attrs.subTitle }}
      </div>
      <el-select v-model="value" :disabled="disabled">
        <el-option v-for="(lender, index) in saleLenders" :key="index" :label="lender.name" :value="lender.name"></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<style scoped></style>
