<script setup>
import { ref, watch, onMounted } from 'vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Type to search ...',
  },
  class: {
    type: String,
    default: '',
  },
  fetchFunction: {
    type: Function,
    required: true,
  },
  strict: {
    type: Boolean,
    default: true,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  valueKey: {
    type: String,
    default: null,
  },
})

const triggerOnFocus = ref(true)
const styleClass = ref(props.class)
const inputValue = ref(props.context.value)
const options = ref([])
const disabled = computed(() => !useFormStatusStore().isEditable)

const fetchSuggestions = async (queryString, cb) => {
  const suggestions = await props.fetchFunction(queryString)
  options.value = suggestions
  cb(suggestions)
}

watch(
  inputValue,
  (newVal) => {
    props.context.node.input(newVal)
  },
  { deep: true },
)

const handleSelect = (item) => {
  inputValue.value = item
}
const handleBlur = () => {
  if (props.strict && inputValue.value && !options.value.includes(inputValue.value)) {
    inputValue.value = ''
  }
}
</script>

<template>
  <div>
    <el-autocomplete
      v-model="inputValue"
      :fetch-suggestions="fetchSuggestions"
      :placeholder="placeholder"
      :class="`w-full ${styleClass}`"
      :clearable="props.clearable"
      :value-key="valueKey"
      :disabled="disabled"
      @select="handleSelect"
      @blur="handleBlur"
    >
      <template #default="{ item }">
        <div>{{ item }}</div>
      </template>
    </el-autocomplete>
  </div>
</template>
