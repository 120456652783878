<script setup>
import { ref } from 'vue'
import { useAddressStore } from '~/stores/addresses.js'
import BasicAutocompleteInput from '~/components/custom-formkit/BasicAutocompleteInput.vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Type to search address ...',
  },
  class: {
    type: String,
    default: '',
  },
})

const store = useAddressStore()
const styleClass = props.class

const inputValue = ref(props.context.value)

const fetchSuggestions = async (queryString) => {
  await store.fetchAddresses(queryString)
  return store.addresses
}
</script>

<template>
  <BasicAutocompleteInput :context="context" :fetch-function="fetchSuggestions" :placeholder="placeholder" :class="styleClass" />
</template>
