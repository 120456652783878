import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service'
import { useRouteStore } from '~/stores/route.js'

export const useFormStore = defineStore({
  id: 'forms',
  state: () => ({
    form: {
      data: {},
      form_files: [],
    },
    errors: {},
    payload: {},
    token: null,
    decodedToken: null,
    urlFormType: null,
  }),
  actions: {
    setPayload(payload) {
      this.payload = payload
    },
    setErrors(errors) {
      this.errors = errors
    },
    async fetchForm() {
      return await axiosService
        .get(`/forms/${useRouteStore().token}`, {
          params: {
            urlFormType: `${useRoute().params.type}`,
          },
        })
        .then((response) => (this.form = response))
        .catch((err) => {
          throw err
        })
    },
    async saveForm(params) {
      const data = {
        ...this.form,
        ...params,
        data: { ...this.payload },
      }
      return await axiosService
        .put(`/forms/${useRouteStore().token}`, data)
        .then((response) => {
          if (params?.status === 'submitted') this.form = response
        })
        .catch((err) => {
          throw err
        })
    },
    async uploadFile(file, key) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('key', key)
      return await axiosService
        .post(`/forms/${useRouteStore().token}/media`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.form.form_files.push(response.data)
          return response.data
        })
        .catch((err) => {
          throw err
        })
    },
    async deleteFile(fileId) {
      return await axiosService
        .delete(`/forms/${useRouteStore().token}/media/${fileId}`)
        .then(() => {
          //Remove the file from the form_files array
          const index = this.form.form_files.findIndex((file) => file.id === fileId)
          if (!index) return
          this.form.form_files.splice(index, 1)
        })
        .catch((err) => {
          throw err
        })
    },
  },
})
