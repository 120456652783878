import { defineStore } from 'pinia'

export const useFormStatusStore = defineStore({
  id: 'form-status',
  state: () => ({
    disabled: false,
    isEditable: false,
    errors: null,
  }),
  actions: {
    setErrors(errors) {
      this.errors = errors
    },
    setDisabled(disabled) {
      this.disabled = disabled
      return this
    },
    setIsEditable(isEditable) {
      this.isEditable = isEditable
      return this
    },
  },
})
